
import namespaces from '@/store/namespaces';
import { AuthorizedUser } from '@/types/users';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import NoCampaigns from '@/shared-components/NoCampaigns.vue';
import SnackBar from '@/ui-components/SnackBar/SnackBar.vue';
import { sharedRouteNames } from '@/route-names/shared';
import PageTitle from '@/ui-components/PageTitle/PageTitle.vue';
import { advertiserRouteNames } from '@/route-names/advertiser';

@Component({
  name: 'DashboardPage',
  components: {
    NoCampaigns,
    SnackBar,
    PageTitle,
  },
})
export default class DashboardPage extends Vue {
  @Getter('userInfo', { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  public campaignCreationRoute = sharedRouteNames.CREATE_CAMPAIGN;

  public get userName() {
    const { NAME = '' } = this.userInfo || {};
    const [firstName] = NAME.split(' ');
    return firstName;
  }

  created() {
    this.$router.onReady(() => {
      setTimeout(() => {
        if (this.$route.name !== advertiserRouteNames.MY_CAMPAIGNS.name) {
          this.$router.replace(advertiserRouteNames.MY_CAMPAIGNS)
        }
      }, 0)
    })
  }
}
