
import { Component, Prop, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import { Location } from 'vue-router';

@Component({
  name: 'NoCampaigns',
  components: {
    IButton,
  },
})
export default class NoCampaigns extends Vue {
  @Prop({ type: String, default: 'no_campaigns_created' }) msg!: string;
  @Prop() campaignCreationRoute!: Location;

  public navigateToCampaignCreation() {
    this.$router.push({
      ...this.campaignCreationRoute
    });
  }
}
